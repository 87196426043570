<template>

	<div class="page">
		<div class="comView">
			<div class="comTitle">收货地址</div>
			<div class="comItem">
				<div class="comView-left" v-if="ShopInfo">
					<div>收货人： <el-input v-model="ShopInfo.name" placeholder="请输入用户名" style="width: 240px;"></el-input></div>
					<div>联系方式： <el-input v-model.trim="ShopInfo.tel" placeholder="请输入用户名" style="width: 240px;"></el-input></div>
					<div>详细地址：<el-input v-model="ShopInfo.address" placeholder="请输入用户名" style="width: 340px;"></el-input></div>
				</div>
				<div><el-button type="primary" @click="handleCart">修改地址</el-button>
					<el-button type="primary" @click="$router.back()">返回</el-button></div>
			</div>
		</div>
		<div class="view">
			<div class="comTitle">商品信息</div>
			<el-table :data="cartList" @selection-change="handleSelectionChange">
				<el-table-column type="selection">
				</el-table-column>
				<el-table-column label="主图">
					<template slot-scope="props">
						<img :src="props.row.cover" alt="" style="width: 80px;height: 80px;">
					</template>
				</el-table-column>
				<el-table-column label="标题" prop="contractNum">
					<template slot-scope="props">
						{{ props.row.goodsName }}
					</template>
				</el-table-column>
				<el-table-column label="规格" prop="medicalServiceName">
					<template slot-scope="props">
						{{ props.row.skuName }}
					</template>
				</el-table-column>
				<el-table-column label="单价" prop="medicalServiceName">
					<template slot-scope="props">
						{{ props.row.price }}
					</template>
				</el-table-column>
				<el-table-column label="数量" prop="memberName">
					<template slot-scope="props">
						<el-input-number v-model="props.row.count" @change="handleChange(props.row, props.row.count)" :min="1"
							:max="10000" label="描述文字"></el-input-number>
					</template>
				</el-table-column>
				<el-table-column label="小计" prop="memberMobile">
					<template slot-scope="props">
						{{ props.row.totalPrice }}
					</template>
				</el-table-column>
				<el-table-column label="操作" width="120">
					<template slot-scope="props">
						<el-button type="text" size="mini" @click="del(props.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="viewBtn" style="margin: 20px">
				<el-button @click="toggleSelection()">删除选中</el-button>
				<div class="viewBtn1">
					<div>已选择{{ count }}件</div>
					<div>商品合计：￥{{ getallprice }}元</div>
					<el-button type="primary" @click="handleCart">提交采购单</el-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			// 筛选
			keyword: '',
			cartList: [],
			multipleSelection: [],
			count: 0,
			Price: 0,
			SelectionList: [],//选中的数组

		}
	},
	computed: {
		ShopInfo() {
			return this.$store.state.shopInfo
		},
		getallprice() {
			let all = 0;
			for (let i = 0; i < this.SelectionList.length; i++) {
				all += this.SelectionList[i].price * this.SelectionList[i].count
			}
			return all
		}
	},
	mounted() {
		this.getcartList();
		console.log(this.ShopInfo, 'ShopInfo');
	},
	methods: {
		// 更改数量
		handleChange(row, value) {
			row.totalPrice = row.count * row.price
			this.multipleSelection.map((item) => {
				if (item.goodsId == row.goodsId) {
					item.count = row.count
				}
			})
			console.log(value, row);
		},
		// 选中
		handleSelectionChange(val) {
			let box = []
			val.map((item) => {
				box.push(item)
			})
			this.SelectionList = box
			this.count = val.length
			let arr = []
			let p = 0
			val.map((item) => {
				p += item.totalPrice
				arr.push({
					"goodsId": item.goodsId,
					"skuId": item.skuId,
					"count": item.count
				})
			})
			this.Price = p
			this.multipleSelection = arr;
		},
		// 多选删除
		toggleSelection() {
			if(this.multipleSelection.length==0){
				this.$message({
						message: '请选择商品',
						type: 'error'
					})
					return 
			}
			let params = {
				lst: this.multipleSelection
			}
			this.$http.post("/product/mall/cart/remove", params).then(res => {
				if (res.code === 0) {
					this.$message({
						message: '删除成功',
						type: 'success'
					})
					this.getcartList()
				}
			})
		},
		// 删除单个
		del(row) {
			let list = []
			list.push({
				"goodsId": row.goodsId,
				"skuId": row.skuId
			})
			let params = {
				"lst": list
			}
			console.log(params, 'params');
			this.$http.post("/product/mall/cart/remove", params).then(res => {
				if (res.code === 0) {
					this.$message({
						message: '删除成功',
						type: 'success'
					})
					this.getcartList()
				}
			})
		},
		// 获取采购车
		getcartList() {
			this.$http.get("/product/mall/cart/list").then(res => {
				if (res.code === 0) {
					res.data.map((item) => {
						item.totalPrice = item.price * item.count
					})
					this.cartList = res.data

				}
			})
		},
		// 提交采购单
		handleCart() {
			if(this.ShopInfo.name==''||this.ShopInfo.tel==''||this.ShopInfo.address==''){
				this.$message({
						message: '请完善收货地址',
						type: 'error'
					})
					return 
			}
			if(this.multipleSelection.length==0){
				this.$message({
						message: '请选择商品',
						type: 'error'
					})
					return 
			}
			let params = {
				receiver: this.ShopInfo.name,
				receiverPhone: this.ShopInfo.tel,
				receiverAddress: this.ShopInfo.address
			}
			let lst = []
			this.multipleSelection.map((item) => {
				lst.push({
					count: item.count,
					goodsId: item.goodsId,
					skuId: item.skuId
				})
			})
			params.orders = lst
			this.$http.post("/product/mall/cart/make/order", params).then(res => {
				if (res.code === 0) {
					this.$message({
						type: 'success',
						message: '提交成功'
					})
					let query = {
						cartId: res.data,
						getallprice: this.getallprice
					}
					this.$router.push({ path: 'supplyCartDetail', query })
				}
			})
		}
	},
}
</script>
<style lang="scss" scoped>
.page {
	height: 100%;
	color: $fontColor;
	display: flex;
	flex-direction: column;
	background: #fff;
	padding: 20px 50px;

	.comTitle {
		margin: 10px 5px;
		font-size: 18px;
	}

	.comView {
		width: 100%;
		background-color: #fff;
		border: 1px solid gainsboro;

		font-size: 15px;
	}

	.comItem {
		padding: 10px 20px;
		display: flex;
		justify-content: space-between;

	}

	.comView-left {
		width: 50%;
		line-height: 30px;

		div {
			display: flex;
		}
	}

	.view {
		overflow: hidden;
		flex: 1;
		background: #fff;
		border: 1px solid gainsboro;
		display: flex;
		flex-direction: column;

		.tableView {
			flex: 1;
		}
	}

	.viewBtn {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
	}

	.viewBtn1 {
		width: 30%;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
}
</style>
